
import { defineComponent, ref } from "vue";
import { Field, ErrorMessage, Form } from "vee-validate";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Report } from "@/store/models/form";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "submit-report",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    let countries = ref([]);
    let formData = ref<Report | any>();
    const store = useStore();
    let promises: any[] = [];
    let promiseMap: any = {};
    let formSubmitted = ref(false);

    formData.value = {
      people_report: {},
      people_report_meta: {},
      people_reporter_meta: {}
    };

     //Create form validation object
    const submitReport = Yup.object().shape({
      reporter_first_name: Yup.string().required().label("First Name"),
      reporter_last_name: Yup.string().required().label("Last Name"),
      reporter_id_type: Yup.string().required().label("ID Type"),
      reporter_id_type_others: Yup.string().when("reporter_id_type", {
        is: "8",
        then: Yup.string().required("Must specify others")
      }).label("ID Type"),
      reporter_id_number: Yup.string().min(4).required().label("ID Number"),
      reporter_relationship_to_accused: Yup.string().min(4).required().label("Relationship to accused"),
      reporter_type: Yup.string().required().label("Reporter Type"),
      reporter_type_others: Yup.string().when("reporter_type", {
        is: "5",
        then: Yup.string().required("Must specify others")
      }).label("Reporter Type"),

      accused_first_name: Yup.string().required().label("First Name"),
      accused_last_name: Yup.string().required().label("Last Name"),
      accused_phone: Yup.string().required().label("Phone"),
      accused_date_of_birth: Yup.date().required().label("Date of birth"),
      accused_id_type: Yup.string().required().label("ID Type"),
      accused_id_type_others: Yup.string().when("accused_id_type", {
        is: "8",
        then: Yup.string().required("Must specify others")
      }).label("ID Type"),
      accused_id_number: Yup.string().min(4).required().label("ID Number"),
      accused_city: Yup.string().min(4).required().label("City"),

      witness_first_name: Yup.string().required().label("First Name"),
      witness_last_name: Yup.string().required().label("Last Name"),
      witness_id_type: Yup.string().required().label("ID Type"),
      witness_id_type_others: Yup.string().when("witness_id_type", {
        is: "8",
        then: Yup.string().required("Must specify others")
      }).label("ID Type"),
      witness_id_number: Yup.string().min(4).required().label("ID Number"),

      report_category: Yup.string().required().label("Report Category"),
      date_of_incidence: Yup.date().required().label("Date of Incidence"),
      place_of_incidence: Yup.string().required().label("Place of Incidence"),
      details_of_incidence: Yup.string().required().label("Details of Incidence"),
    });

    const validateFile = (input: HTMLInputElement) => {
      if (!input.files?.length) {
        return;
      }

      const file = input.files[0];
      if (file && file.size / (1024 * 1024) > 2) {
        input.value = "";
        Swal.fire({
          text: "File cannot be larger than 2 MB",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        return false;
      }

      return true;
    }

    const changeFile = async (fieldName, event: Event) => {
      const input = event.target as HTMLInputElement;
      if (!input.files?.length) {
        return;
      } 
      const file: any = input.files[0];
      if(file && validateFile(input)) {
        //trigger save
        const file_doc = {
          fileBlob: file,
          description: `Meta Doc Submit Report ${store.getters.currentUser.username}`,
          alt: "Report Submit Doc",
          file_name: file.name
        };
        promiseMap[promises.length] = fieldName;
        promises.push(file_doc);
      }      
    };

    const onSubmitReport = async () => {
      await saveAttachments();      
    }

    const saveAttachments = () => {
      let mediaPromises: any[] = [];
      promises.forEach(element => {
        mediaPromises.push(store.dispatch(Actions.CREATE_PDF_MEDIA, element))
      });
      return Promise.all(mediaPromises).then(async (data) => {
        for(var attIndex = 0; attIndex < data.length; attIndex++) {
          const fieldName = promiseMap[attIndex];
          formData.value[fieldName] = data[attIndex].data.id;
        }
        await saveReport();
      })
    }

    const saveReport = () => {
      return ApiService.post("report/mrbSearch", formData.value as any)
        .then(() => {
          formSubmitted.value = true;
        })
        .catch(({ response }) => {
          var errorKeys = Object.keys(response.data);
          var errorList: any[] = [];
          errorKeys.forEach(element => {
            errorList.push(response.data[element]);
          });
          Swal.fire({
            html: errorList.join(","),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
    }

    return {
      countries,
      formData,
      submitReport,
      onSubmitReport,
      changeFile,
      formSubmitted,
    };
  },
});
