
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter, useRoute } from "vue-router";
import { formatDate } from "@/filters";
import { CUSTOMER, ADMIN, REVIEWER, CLIENT } from "@/constants";

interface IClient {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  last_modified: string;
}

export default defineComponent({
  name: "customer-profile",
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const user = ref({});

    onMounted(async () => {
      if (store.getters.isUserAuthenticated) {
        user.value = store.getters.currentUser
      }
    });

    return {
      formatDate,
      user,
    };
  },
});
