
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-toolbar-buttons",
  components: {},
  setup() {
    const mrbUrlContact = process.env.VUE_APP_MRB_URL_CONTACT;

    return {
      mrbUrlContact
    }
  }
});
