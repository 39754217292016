import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import router from "@/router";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.defaults.headers.common["apptype"] = "MRBSEARCH";
    this.setUpAxiosInterceptors(ApiService.vueInstance);
  }

  public static setUpAxiosInterceptors(v: any) {
    axios.interceptors.request.use(
      function (config: any) {
        // if (config.blocking) {
        //   v.$store.dispatch(Actions.START_LOAD);
        // }
        store.dispatch(Actions.START_LOAD);

        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response: any) {
        // if (response.config.blocking) {
        //     v.$store.dispatch(Actions.FINISH_LOAD);
        // }
        store.dispatch(Actions.FINISH_LOAD);

        return response;
      },
      function (error) {
        // if (error.config.blocking) {
        //     v.$store.dispatch(Actions.FINISH_LOAD);
        // }
        store.dispatch(Actions.FINISH_LOAD);
        //401 handler
        if (401 === error?.response?.status) {
          router.push({ name: "sign-in" });
        } else {
          return Promise.reject(error);
        }
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  public static removeAuthorizationHeader(): void {
    delete ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ];
    JwtService.destroyToken();
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static getWithConfig(
    resource: string,
    config: any
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(`${resource}`, config);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  public static patch(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource);
  }

  public static createMedia(
    file: string,
    description: string,
    alt: string
  ): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("srcFile", file);
    formData.append("alt_text", alt);
    formData.append("media_description", description);
    return axios.post("media/", formData, {
      headers: {
        "Content-Type": 'multipart/form-data',
        Authorization: `Token ${JwtService.getToken()}`,
      },
    });
  }

  public static isAxiosResponse = (
    response: AxiosResponse<any> | AxiosError<any>
  ): response is AxiosResponse => {
    return (response as AxiosResponse).status !== undefined;
  };

  public static isAxiosError = (
    response: AxiosResponse<any> | AxiosError<any>
  ): response is AxiosError => {
    return (response as AxiosError).isAxiosError !== undefined;
  };
}

export default ApiService;
