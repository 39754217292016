import { DashboardStats } from "@/constants";

const AdminMainMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/admin/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Customer",
    route: "/admin/formSubmission",
    pages: [
      {
        sectionTitle: "Customers",
        route: "/admin/invitations",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Applicants Listing",
            route: "/admin/invitations/pending",
            counter: DashboardStats.INVITED_PENDING_REVIEW
          },
          {
            heading: "Sent Invitations",
            route: "/admin/invitations/new",
            counter: DashboardStats.INVITED_DONE
          },
          {
            heading: "Customer Details",
            route: "/admin/customer/customerDetails"
          },
        ],
      },
      {
        sectionTitle: "Invited KYC Applicants",
        route: "/admin/kycSubmission",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Pending Submission",
            route: "/admin/kycSubmission/inProgress",
            counter: DashboardStats.KYC_VERIFICATION_PENDING_SUBMISSION
          },
          {
            heading: "Pending Review",
            route: "/admin/kycSubmission/submitted",
            counter: DashboardStats.KYC_VERIFICATION_PENDING_REVIEW
          },
          {
            heading: "In Review",
            route: "/admin/kycSubmission/inReview",
            counter: DashboardStats.KYC_VERIFICATION_IN_REVIEW
          },
          {
            heading: "Approved",
            route: "/admin/kycSubmission/approved",
            counter: DashboardStats.KYC_VERIFICATION_APPROVED
          },
          {
            heading: "Declined",
            route: "/admin/kycSubmission/declined",
            counter: DashboardStats.KYC_VERIFICATION_DECLINED
          },
        ],
      },
      {
        sectionTitle: "Accounts",
        route: "/admin/companies",
        svgIcon: "media/icons/duotune/art/art009.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Companies",
            route: "/admin/companies",
            counter: DashboardStats.COMPANIES
          }
        ],
      },      
    ],
  },
  {
    heading: "Admin",
    route: "/admin/users",
    pages: [
      {
        sectionTitle: "Users",
        route: "/admin/users",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Review Users",
            route: "/admin/users/personal"
          },
          {
            heading: "Admin Users",
            route: "/admin/users/admin"
          },
          {
            heading: "Add New User",
            route: "/admin/users/addNewUser"
          },
        ],
      },
      {
        sectionTitle: "Subscriptions",
        route: "/admin/subscriptions",
        svgIcon: "media/icons/duotune/art/art009.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Add Subscription",
            route: "/admin/subscriptions/add"
          },
          {
            heading: "View Subscription",
            route: "/admin/subscriptions/view"
          }
        ],
      },
    ],
  },
];

export default AdminMainMenuConfig;
