enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  REGISTER_REVIEWER = "registerReviewer",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  CONFIRM_RESET_PASSWORD = "confirmResetPassword",
  CONFIRM_CLIENT_SIGNUP = "confirmClientSignup",
  CONFIRM_REVIEWER_SIGNUP = "confirmReviewerSignup",
  SEND_EMAIL_OTP = "sendEmailOtp",
  VERIFY_EMAIL_OTP = "verifyEmailOtp",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  USER_DATA = "USER_DATA",
  //body
  START_LOAD = "startLoad",
  FINISH_LOAD = "finishLoad",
  //form
  FETCH_FORMS = "fetchForms",
  FETCH_FORMS_QUERY = "fetchFormsQuery",
  FETCH_FORM_BY_ID = "fetchFormById",
  FETCH_FORM_REVIEW_BY_ID = "fetchFormReviewById",
  SAVE_PERSONAL_INFO = "savePersonalInfo",
  GET_EDUCATION_HISTORY = "getEducationHistory",
  GET_EMPLOYMENT_HISTORY = "getEmploymentHistory",
  GET_ADDRESS_HISTORY = "getAddressHistory",
  GET_CUSTOMER_PLANS = "getCustomerPlansList",
  SAVE_EDUCATION_HISTORY = "saveEducationHistory",
  SAVE_EMPLOYMENT_HISTORY = "saveEmploymentHistory",
  SAVE_ADDRESS_HISTORY = "saveAddressHistory",
  UPDATE_FORM_STATUS = "updateFormStatus",
  UPDATE_EDUCATION_HISTORY = "updateEducationHistory",
  UPDATE_EMPLOYMENT_HISTORY = "updateEmploymentHistory",
  UPDATE_ADDRESS_HISTORY = "updateAddressHistory",
  DELETE_EDUCATION_HISTORY = "deleteEducationHistory",
  DELETE_EMPLOYMENT_HISTORY = "deleteEmploymentHistory",
  DELETE_ADDRESS_HISTORY = "deleteAddressHistory",
  GET_CURRENT_STEP = "getCurrentStep",
  GET_COUNTRIES = "getCountriesList",
  GET_COMPANIES = "getCompaniesList",
  GET_COMPANY_USERS = "getCompaniesUsersList",
  GET_CUSTOMER_DASHBOARD = "getCustomerDashboardMetaData",
  GET_ADMIN_DASHBOARD = "getAdminDashboardMetaData",
  UPDATE_CURRENT_STEP = "updateCurrentStep",
  CREATE_PDF_MEDIA = "createPdfMedia",
  CREATE_FORM_ATTACHMENT = "createFormAttachment",
  CREATE_MEDIA_ATTACHMENT = "createMediaAttachment",

  //kyc
  SAVE_KYC = "saveKyc",
  FETCH_KYCS = "fetchKycs",
  FETCH_KYC_QUERY = "fetchKycsQuery",
  FETCH_KYC_BY_ID = "fetchKycById",
  FETCH_KYC_REVIEW_BY_ID = "fetchKycReviewById",
  UPDATE_KYC_STATUS = "updateKycStatus",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH_TOKEN = "setAuthToken",
  SET_AUTH_USER = "setAuthUser",
  SET_USER = "setUser",
  SET_USER_ROLES = "setUserRoles",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_KYC_ERROR = "setKycError",
  SET_CUSTOMER_ERROR = "setCustomerError",
  SET_CUSTOMER_PLANS = "setCustomerPlans",
  SET_COUNTRIES = "setCountries",
  SET_COMPANIES = "setCompanies",
  SET_COMPANY_USERS = "setCompanyUsers",
  SET_CUSTOMER_DASHBOARD = "setCustomerDashboard",
  SET_CUSTOMER_DASHBOARD_FETCHED = "setCustomerDashboardFetched",
  SET_ADMIN_DASHBOARD = "setAdminDashboard",
  SET_ADMIN_DASHBOARD_FETCHED = "setAdminDashboardFetched",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_APP_LOADING = "setAppLoading",
  SET_FORM = "setForm",
  SET_FORM_REVIEW = "setFormReview",
  SET_FORMS = "setForms",
  SET_FORM_ID = "setFormId",
  SET_FORM_CURRENT_STEP = "setFormCurrentStep",
  SET_EDUCATION_HISTORY = "setEducationHistory",
  SET_EMPLOYMENT_HISTORY = "setEmploymentHistory",
  SET_ADDRESS_HISTORY = "setAddressHistory",
  SET_FORM_LOADING = "setFormLoading",
  //kyc
  SET_KYC = "setKyc",
  SET_KYC_REVIEW = "setKycReview",
  SET_KYCS = "setKycs",
  SET_KYC_ID = "setKycId",
  SET_KYC_CURRENT_STEP = "setKycCurrentStep",
}

export { Actions, Mutations };
