import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class KYCModule extends VuexModule {
  errors = {};
  kycId = 0;
  kyc: any = {};
  kycReview: any = {};
  kycs = [];

  /**
   * Get authentification errors
   * @returns array
   */
  get getKycErrors() {
    return this.errors;
  }

  get getKycId() {
    return this.kycId;
  }

  get getKyc() {
    return this.kyc;
  }

  get getKycReview() {
    return this.kycReview;
  }

  get getKycs() {
    return this.kycs;
  }

  @Mutation
  [Mutations.SET_KYC_ERROR](error) {
    if (error && typeof error !== "string") {
      this.errors = { ...error };
    } else {
      this.errors = { error: ["Server Error. Please try again in a while"] };
    }
  }

  @Mutation
  [Mutations.SET_KYC](kyc) {
    if (kyc && typeof kyc !== "string") {
      this.kyc = { ...kyc };
    }
  }

  @Mutation
  [Mutations.SET_KYC_REVIEW](kycReview) {
    if (kycReview && typeof kycReview !== "string") {
      this.kycReview = kycReview;
    }
  }

  @Mutation
  [Mutations.SET_KYCS](kycs) {
    this.kycs = kycs ?? [];
  }

  @Mutation
  [Mutations.SET_KYC_ID](kycId) {
    this.kycId = kycId;
  }

  @Action
  [Actions.SAVE_KYC](payload) {
    return ApiService.post("kyc/", payload)
      .then((response: any) => {
        this.context.commit(Mutations.SET_KYC_ID, response.data.id);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_KYC_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_KYCS](userId) {
    return ApiService.get("kyc", `?user=${userId}`)
      .then((response: any) => {
        if (response.data.count && response.data.count > 0) {
          this.context.commit(
            Mutations.SET_KYC_ID,
            response.data.results[0].id
          );
          this.context.commit(
            Mutations.SET_KYC,
            response.data.results[0]
          );
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_KYC_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_KYC_QUERY](options?: {
    search?: string;
    user__parent?: number;
    user?: number;
    status?: number;
    page?: number;
  }) {
    return ApiService.query("kyc", { params: options })
      .then((response: any) => {
        this.context.commit(Mutations.SET_KYCS, response.data.results);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_KYC_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_KYC_BY_ID](kycId) {
    if (!kycId) return;
    return ApiService.get(`kyc/${kycId}`)
      .then((response: any) => {
        this.context.commit(Mutations.SET_KYC, response.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_KYC_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_KYC_REVIEW_BY_ID](kycId) {
    if (!kycId) return;
    return ApiService.get(`kycReview/${kycId}`)
      .then((response: any) => {
        this.context.commit(Mutations.SET_KYC_REVIEW, response.data);
      })
      .catch(({ response }) => {
        //this.context.commit(Mutations.SET_KYC_ERROR, response?.data);
      });
  }

  @Action
  [Actions.UPDATE_KYC_STATUS](payload) {
    return ApiService.patch(`kyc/${payload.id}/`, payload.data)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_KYC_ERROR, response?.data);
      });
  }
}