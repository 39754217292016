export const CUSTOMER = 1;
export const ADMIN = 2;
export const REVIEWER = 3;
export const CLIENT = 4;

export const IN_PROGRESS = 1;
export const COMPLETED = 2;
export const CENCEL = 3;
export const IN_REVIEW = 4;
export const SUBMITTED = 5;

export const PERSONAL_PLAN = 1;
export const BUSINESS_PLAN = 2;

export const enum DashboardStats {
    VERIFICATION_PENDING_SUBMISSION = "verificationPendingSubmission",
    VERIFICATION_PENDING_REVIEW = "verificationPendingReview",
    VERIFICATION_IN_REVIEW = "verificationInReview",
    VERIFICATION_APPROVED = "verificationApproved",
    VERIFICATION_DECLINED = "verificationDeclined",
    COMPANIES = "companies",
    INVITED_PENDING_REVIEW = "invitedPendingReview",
    INVITED_DONE = "invitedDone",
    //kyc
    KYC_VERIFICATION_PENDING_SUBMISSION = "kycVerificationPendingSubmission",
    KYC_VERIFICATION_PENDING_REVIEW = "kycVerificationPendingReview",
    KYC_VERIFICATION_IN_REVIEW = "kycVerificationInReview",
    KYC_VERIFICATION_APPROVED = "kycVerificationApproved",
    KYC_VERIFICATION_DECLINED = "kycVerificationDeclined",
}