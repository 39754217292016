
import ApiService from "@/core/services/ApiService";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "mrb-search-view-report",
  setup() {
    const tableData = ref<Array<any>>([]);
    const route = useRoute();
    const reportData = ref<any>({});
    const docTypes = ref({
      "1": 'Ghana Card',
      "2": 'Residence Card',
      "3": 'Passport',
    });

    const reportTypes = ref({
      "1": 'FRAUD',
      "2": 'THEFT',
      "3": 'UNPROFESSIONALISM',
      "4": 'INCOMPETENCE',
    });
    
    const fetchReport = () => {
      tableData.value = [];
      ApiService.get(`report/mrbSearch/${route.params.peopleReportId}`)
        .then(({ data }) => {
          console.log(data);
          reportData.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };
    onMounted(async () => {
      fetchReport();
    });

    return {
      reportData,
      docTypes,
      reportTypes
    }
  }
});
