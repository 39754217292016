import { DashboardStats } from "@/constants";

const ReviewMainMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/review/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Customer",
    route: "/review/formSubmission",
    pages: [
      {
        sectionTitle: "Customers",
        route: "/review/invitations",
        svgIcon: "media/icons/duotune/general/gen025.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Applicants Listing",
            route: "/review/invitations/pending",
            counter: DashboardStats.INVITED_PENDING_REVIEW
          },
          {
            heading: "Sent Invitations",
            route: "/review/invitations/new",
            counter: DashboardStats.INVITED_DONE
          },
          {
            heading: "Customer Details",
            route: "/review/customer/customerDetails"
          },
        ],
      },
      {
        sectionTitle: "Invited BGC Applicants",
        route: "/review/formSubmission",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Pending Submission",
            route: "/review/formSubmission/inProgress",
            counter: DashboardStats.VERIFICATION_PENDING_SUBMISSION
          },
          {
            heading: "Pending Review",
            route: "/review/formSubmission/submitted",
            counter: DashboardStats.VERIFICATION_PENDING_REVIEW
          },
          {
            heading: "In Review",
            route: "/review/formSubmission/inReview",
            counter: DashboardStats.VERIFICATION_IN_REVIEW
          },
          {
            heading: "Approved",
            route: "/review/formSubmission/approved",
            counter: DashboardStats.VERIFICATION_APPROVED
          },
          {
            heading: "Declined",
            route: "/review/formSubmission/declined",
            counter: DashboardStats.VERIFICATION_DECLINED
          },
        ],
      },
      {
        sectionTitle: "Invited KYC Applicants",
        route: "/review/kycSubmission",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Pending Submission",
            route: "/review/kycSubmission/inProgress",
            counter: DashboardStats.KYC_VERIFICATION_PENDING_SUBMISSION
          },
          {
            heading: "Pending Review",
            route: "/review/kycSubmission/submitted",
            counter: DashboardStats.KYC_VERIFICATION_PENDING_REVIEW
          },
          {
            heading: "In Review",
            route: "/review/kycSubmission/inReview",
            counter: DashboardStats.KYC_VERIFICATION_IN_REVIEW
          },
          {
            heading: "Approved",
            route: "/review/kycSubmission/approved",
            counter: DashboardStats.KYC_VERIFICATION_APPROVED
          },
          {
            heading: "Declined",
            route: "/review/kycSubmission/declined",
            counter: DashboardStats.KYC_VERIFICATION_DECLINED
          },
        ],
      },
    ],
  },
];

export default ReviewMainMenuConfig;
