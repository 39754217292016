import { RouteRecordRaw } from "vue-router";
import Profile from "@/components/mrbSearch/Profile.vue";
import Disclaimer from "@/components/mrbSearch/Disclaimer.vue";
import SubmitReport from "@/components/mrbSearch/SubmitReport.vue";
import ViewReport from "@/components/mrbSearch/ViewReport.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "submitReport",
    name: "mrbSearch-submitReport",
    component: SubmitReport,
  },
  {
    path: "viewReport/:peopleReportId",
    name: "mrbSearch-viewReport",
    component: ViewReport,
  },
  {
    path: "profile",
    name: "mrbSearch-profile",
    component: Profile,
  },
  {
    path: "disclaimer",
    name: "mrbSearch-disclaimer",
    component: Disclaimer,
  },
  {
    path: "",
    name: "mrbSearch-home",
    redirect: { name: 'mrbSearch-submitReport' },
  },
];

export default routes;
