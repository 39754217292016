import { DashboardStats } from "@/constants";

const CustomerMainMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/customer/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Applicants",
    route: "/customer",
    pages: [
      {
        sectionTitle: "Get Started",
        route: "/customer/applicant",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Add New Applicant",
            route: "/customer/applicant/add",
          },
          {
            heading: "Submitted Applicants",
            route: "/customer/applicant/invited/pending",
          },
          {
            heading: "Accepted Applicants",
            route: "/customer/applicant/invited/approved",
          },
        ],
      },
      {
        sectionTitle: "KYC Verifications",
        route: "/customer/kycProgress",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Pending Submission",
            route: "/customer/kycProgress/inProgress",
            counter: DashboardStats.KYC_VERIFICATION_PENDING_SUBMISSION
          },
          {
            heading: "Pending Review",
            route: "/customer/kycProgress/submitted",
            counter: DashboardStats.KYC_VERIFICATION_PENDING_REVIEW
          },
          {
            heading: "In Review",
            route: "/customer/kycProgress/inReview",
            counter: DashboardStats.KYC_VERIFICATION_IN_REVIEW
          },
          {
            heading: "Approved",
            route: "/customer/kycProgress/approved",
            counter: DashboardStats.KYC_VERIFICATION_APPROVED
          },
          {
            heading: "Declined",
            route: "/customer/kycProgress/declined",
            counter: DashboardStats.KYC_VERIFICATION_DECLINED
          },
        ],
      },
    ],
  },
];

export default CustomerMainMenuConfig;
