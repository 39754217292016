import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class FormModule extends VuexModule {
  errors = {};
  formId = 0;
  form: any = {};
  formReview: any = {};
  formCurrentStep = 0;
  educationHistory = [];
  employmentHistory = [];
  addressHistory = [];
  countries = [];
  forms = [];

  /**
   * Get authentification errors
   * @returns array
   */
  get getFormErrors() {
    return this.errors;
  }

  get getFormId() {
    return this.formId;
  }

  get getForm() {
    return this.form;
  }

  get getFormReview() {
    return this.formReview;
  }

  get getForms() {
    return this.forms;
  }

  get getFormCurrentStep() {
    return this.formCurrentStep;
  }

  get getEduHistory() {
    return this.educationHistory;
  }

  get getEmpHistory() {
    return this.employmentHistory;
  }

  get getAddHistory() {
    return this.addressHistory;
  }

  get getCountries() {
    return this.countries;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    if (error && typeof error !== "string") {
      this.errors = { ...error };
    } else {
      this.errors = { error: ["Server Error. Please try again in a while"] };
    }
  }

  @Mutation
  [Mutations.SET_COUNTRIES](countries) {
    this.countries = countries ?? [];
  }

  @Mutation
  [Mutations.SET_FORM](form) {
    if (form && typeof form !== "string") {
      this.form = { ...form };
      if (form.education_history) {
        this.educationHistory = form.education_history;
      }
      if (form.employment_history) {
        this.employmentHistory = form.employment_history;
      }
      if (form.address_history) {
        this.form.address_history = form.address_history;
      }
    }
  }

  @Mutation
  [Mutations.SET_FORM_REVIEW](formReview) {
    if (formReview && typeof formReview !== "string") {
      this.formReview = formReview;
    }
  }

  @Mutation
  [Mutations.SET_FORMS](forms) {
    this.forms = forms ?? [];
  }

  @Mutation
  [Mutations.SET_FORM_ID](formId) {
    this.formId = formId;
  }

  @Mutation
  [Mutations.SET_FORM_CURRENT_STEP](formCurrentStep) {
    this.formCurrentStep = formCurrentStep;
  }

  @Mutation
  [Mutations.SET_EDUCATION_HISTORY](history) {
    if (history && typeof history !== "string") {
      this.educationHistory = history;
    }
  }

  @Mutation
  [Mutations.SET_EMPLOYMENT_HISTORY](history) {
    if (history && typeof history !== "string") {
      this.employmentHistory = history;
    }
  }

  @Mutation
  [Mutations.SET_ADDRESS_HISTORY](history) {
    if (history && typeof history !== "string") {
      this.addressHistory = history;
    }
  }

  @Action
  [Actions.SAVE_PERSONAL_INFO](payload) {
    return ApiService.post("connectedForms/", payload)
      .then((response: any) => {
        this.context.commit(Mutations.SET_FORM_ID, response.data.id);
        this.context.commit(Mutations.SET_FORM_CURRENT_STEP, 1);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_CURRENT_STEP]() {
    if (!this.formId) return;
    return ApiService.get(
      "connectedFormsCurrentStep",
      `?connected_from=${this.formId}`
    )
      .then((response: any) => {
        console.log(response);
        this.context.commit(
          Mutations.SET_FORM_CURRENT_STEP,
          response.data.results[0].step
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_COUNTRIES]() {
    return ApiService.get("countries-list")
      .then((response: any) => {
        console.log(response);
        this.context.commit(Mutations.SET_COUNTRIES, response.data.countries);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.UPDATE_CURRENT_STEP](payload) {
    return ApiService.put(
      `connectedFormsCurrentStep/${payload.id}/`,
      payload.data
    )
      .then((response: any) => {
        console.log(response);
        this.context.commit(Mutations.SET_FORM_CURRENT_STEP, payload.data.step);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_FORMS](userId) {
    return ApiService.get("connectedForms", `?user=${userId}`)
      .then((response: any) => {
        if (response.data.count && response.data.count > 0) {
          this.context.commit(
            Mutations.SET_FORM_ID,
            response.data.results[0].id
          );
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_FORMS_QUERY](options?: {
    search?: string;
    user__parent?: number;
    user?: number;
    status?: number;
    page?: number;
  }) {
    return ApiService.query("connectedForms", { params: options })
      .then((response: any) => {
        this.context.commit(Mutations.SET_FORMS, response.data.results);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_FORM_BY_ID](formId) {
    if (!formId) return;
    return ApiService.get(`connectedForms/${formId}`)
      .then((response: any) => {
        this.context.commit(Mutations.SET_FORM, response.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.FETCH_FORM_REVIEW_BY_ID](formId) {
    if (!formId) return;
    return ApiService.get(`connectedFormsReview/${formId}`)
      .then((response: any) => {
        this.context.commit(Mutations.SET_FORM_REVIEW, response.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_EDUCATION_HISTORY]() {
    if (!this.formId) return;
    return ApiService.get("education-history", `?connected_from=${this.formId}`)
      .then((response) => {
        this.context.commit(
          Mutations.SET_EDUCATION_HISTORY,
          response.data.results
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_EMPLOYMENT_HISTORY]() {
    if (!this.formId) return;
    return ApiService.get(
      "emnployment-history",
      `?connected_from=${this.formId}`
    )
      .then((response) => {
        this.context.commit(
          Mutations.SET_EMPLOYMENT_HISTORY,
          response.data.results
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.GET_ADDRESS_HISTORY]() {
    if (!this.formId) return;
    return ApiService.get("address-history", `?connected_from=${this.formId}`)
      .then((response) => {
        this.context.commit(
          Mutations.SET_ADDRESS_HISTORY,
          response.data.results
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.SAVE_EDUCATION_HISTORY](payload) {
    return ApiService.post("education-history/", payload)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.SAVE_EMPLOYMENT_HISTORY](payload) {
    return ApiService.post("emnployment-history/", payload)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.SAVE_ADDRESS_HISTORY](payload) {
    return ApiService.post("address-history/", payload)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.UPDATE_FORM_STATUS](payload) {
    return ApiService.patch(`connectedForms/${payload.id}/`, payload.data)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.UPDATE_EDUCATION_HISTORY](payload) {
    return ApiService.put(`education-history/${payload.id}/`, payload.data)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.UPDATE_EMPLOYMENT_HISTORY](payload) {
    return ApiService.put(`emnployment-history/${payload.id}/`, payload.data)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.UPDATE_ADDRESS_HISTORY](payload) {
    return ApiService.put(`address-history/${payload.id}`, payload.data)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.DELETE_EDUCATION_HISTORY](id) {
    return ApiService.delete(`education-history/${id}`)
      .then((response) => {
        this.context.commit(Mutations.SET_EMPLOYMENT_HISTORY, response.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.DELETE_EMPLOYMENT_HISTORY](id) {
    return ApiService.delete(`emnployment-history/${id}`)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.DELETE_ADDRESS_HISTORY](id) {
    return ApiService.delete(`address-history/${id}`)
      .then((data) => {
        console.log(data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.CREATE_PDF_MEDIA](payload) {
    return ApiService.createMedia(
      payload.fileBlob,
      payload.description,
      payload.alt
    );
  }

  @Action
  [Actions.CREATE_FORM_ATTACHMENT](payload) {
    return ApiService.post("form-attachment/", payload)
      .then((response: any) => {
        console.log(response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }

  @Action
  [Actions.CREATE_MEDIA_ATTACHMENT](payload) {
    return ApiService.post("media/", payload)
      .then((response: any) => {
        console.log(response);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response?.data);
      });
  }
}
