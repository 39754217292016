import { DashboardStats } from "@/constants";

const AdminMainMenuConfig = [
  {
    pages: [
      
    ],
  },
];

export default AdminMainMenuConfig;
