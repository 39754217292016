import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import MrbSearchMain from "@/components/mrbSearch/Main.vue";
import { MrbSearch } from "@/routes/route-guards";
import publicRoutes from "@/routes/public";
import mrbSearchLayoutConfig from "@/core/config/MrbSearchLayoutConfig";
import mrbSearch from "@/routes/mrbSearch";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/mrbSearch",
    name: "mrbSearch",
    component: MrbSearchMain,
    beforeEnter: MrbSearch,
    children: [...mrbSearch],
  },
  ...publicRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to) => {
  // reset config to initial state
  if (to && to.path.startsWith("/mrbSearch")) {
    store.commit(Mutations.SET_LAYOUT_CONFIG, mrbSearchLayoutConfig);
  }

  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
