
import { defineComponent, watch, ref } from "vue";
import MrbSearchLayout from "@/layout/Layout.vue";
import KTLoader from "@/components/CircleLoader.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "ClientMain",
  components: {
    MrbSearchLayout,
    KTLoader,
  },
  setup() {
    const store = useStore();
    let loading = ref(false);

    watch(
      () => store.getters.appLoading,
      (curVal) => {
        loading.value = curVal;
      }
    );

    return {
      loading,
    };
  },
});
